import { useEffect, useState } from 'react'

import * as Routes from '../../routes'
import { ManufacturerSelect, Select } from './FormElements'
import { range, standardHeaders } from './utils'

const VehicleBuilder = ({
  manufacturer,
  setManufacturer,
  family,
  setFamily,
  year,
  setYear,
  defaultFamilyId,
  showBadge = false,
  defaultYear,
  bodyconfiguration,
  wheelbaseconfiguration,
  setWheelbaseconfiguration,
  setBodyconfiguration,
  bodyStyle,
  setBodyStyle,
  modelYear,
  setModelYear,
  badge,
  setBadge,
  colourOptions,
  colour,
  setColour,
  badgeHint,
}) => {
  let [yearOptions, setYearOptions] = useState()
  let [badgeOptions, setBadgeOptions] = useState()
  let [families, setFamilies] = useState()
  let [bodyconfigurationOptions, setBodyconfigurationOptions] = useState()
  let [wheelbaseconfigurationOptions, setWheelbaseconfigurationOptions] = useState()
  let [familyOptions, setFamilyOptions] = useState()
  let [modelYearOptions, setModelYearOptions] = useState()
  let [bodyStyleOptions, setBodyStyleOptions] = useState()

  useEffect(() => {
    if (!manufacturer) {
      return
    }

    let families_url = Routes.manufacturer_families_path(manufacturer)

    setFamily(defaultFamilyId || null)
    setYear(defaultYear || null)
    setYearOptions(null)
    if (manufacturer) {
      fetch(families_url, { headers: standardHeaders })
        .then((res) => res.json())
        .then((res) => {
          setFamilies(res)
          setFamilyOptions(
            res.map((m) => {
              return { value: m.id, label: m.name }
            })
          )
        })
    }
  }, [manufacturer])

  useEffect(() => {
    setYear(defaultYear || null)
    if (family && families) {
      let current_family = families.filter((f) => f.id == family)[0]
      if (!current_family) {
        return
      }
      let years = range(current_family.year_start, current_family.year_end, 1)
      setYearOptions(
        years
          .map((y) => {
            return { value: y, label: y }
          })
          .reverse()
      )
      let newBadgeOptions = current_family.badges.map((b) => {
        return { value: b, label: b || '(No Badge)' }
      })
      setBadgeOptions([{ value: '', label: 'Select an option...' }, ...newBadgeOptions])
      setBodyconfigurationOptions(
        current_family.bodyconfigurations.map((b) => {
          return { value: b, label: b }
        })
      )
      setWheelbaseconfigurationOptions(
        current_family.wheelbaseconfigurations.map((b) => {
          return { value: b, label: b }
        })
      )
      setModelYearOptions(
        current_family.modelyears.map((b) => {
          return { value: b, label: b }
        })
      )
      setBodyStyleOptions(
        current_family.bodystyles.map((b) => {
          return { value: b, label: b }
        })
      )
    }
  }, [family, families])

  return (
    <>
      <div className="form-group">
        <ManufacturerSelect manufacturer={manufacturer} setManufacturer={setManufacturer} />
      </div>
      <div className="form-group">
        <Select
          label="Model"
          options={familyOptions}
          isDisabled={!familyOptions}
          value={familyOptions && familyOptions.filter((o) => o.value === family)}
          onChange={(e) => setFamily(e.value)}
          placeholder="Model..."
          className="family-select"
          menuPortalTarget={document.body}
        />
      </div>
      <div className="form-group">
        <Select
          label="Year"
          isDisabled={!yearOptions}
          options={yearOptions}
          value={yearOptions && yearOptions.filter((o) => o.value === year)}
          onChange={(e) => setYear(e.value)}
          placeholder="Year..."
          className="year-select"
          menuPortalTarget={document.body}
        />
      </div>
      {showBadge && (
        <>
          <div className="form-group">
            <Select
              label="Badge"
              isDisabled={!badgeOptions}
              options={badgeOptions}
              value={badgeOptions && badgeOptions.filter((o) => o.value === badge)}
              onChange={(e) => setBadge(e.value)}
              placeholder="Badge..."
              hint={badgeHint}
              menuPortalTarget={document.body}
            />
          </div>
          {bodyconfigurationOptions && bodyconfigurationOptions.length > 1 && (
            <div className="form-group">
              <Select
                label="Body Configuration"
                isDisabled={!bodyconfigurationOptions}
                options={bodyconfigurationOptions}
                value={
                  bodyconfigurationOptions &&
                  bodyconfigurationOptions.filter((o) => o.value === bodyconfiguration)
                }
                onChange={(e) => setBodyconfiguration(e.value)}
                placeholder="Body configuration..."
                menuPortalTarget={document.body}
              />
            </div>
          )}
          {wheelbaseconfigurationOptions && wheelbaseconfigurationOptions.length > 1 && (
            <div className="form-group">
              <Select
                label="Wheelbase Configuration"
                isDisabled={!wheelbaseconfigurationOptions}
                options={wheelbaseconfigurationOptions}
                value={
                  wheelbaseconfigurationOptions &&
                  wheelbaseconfigurationOptions.filter((o) => o.value === wheelbaseconfiguration)
                }
                onChange={(e) => setWheelbaseconfiguration(e.value)}
                placeholder="Wheelbase configuration..."
                menuPortalTarget={document.body}
              />
            </div>
          )}
          {typeof setModelYear === 'function' &&
            modelYearOptions &&
            modelYearOptions.length > 1 && (
              <div className="form-group">
                <Select
                  label="Model Year"
                  isDisabled={!modelYearOptions}
                  options={modelYearOptions}
                  value={modelYearOptions && modelYearOptions.filter((o) => o.value === modelYear)}
                  onChange={(e) => setModelYear(e.value)}
                  placeholder="Model year..."
                  hint="If blank, will apply to all model years"
                  menuPortalTarget={document.body}
                />
              </div>
            )}
          {typeof setBodyStyle === 'function' &&
            bodyStyleOptions &&
            bodyStyleOptions.length > 1 && (
              <div className="form-group">
                <Select
                  label="Body Style"
                  isDisabled={!bodyStyleOptions}
                  options={bodyStyleOptions}
                  value={bodyStyleOptions && bodyStyleOptions.filter((o) => o.value === bodyStyle)}
                  onChange={(e) => setBodyStyle(e.value)}
                  placeholder="Body style..."
                  hint="If blank, will apply to all body styles"
                  menuPortalTarget={document.body}
                />
              </div>
            )}
          <div className="form-group">
            <Select
              label="Colour"
              isDisabled={!colourOptions}
              options={colourOptions}
              value={colourOptions && colourOptions.filter((o) => o.value === colour)}
              onChange={(e) => setColour(e.value)}
              placeholder="Colour..."
              menuPortalTarget={document.body}
            />
          </div>
        </>
      )}
    </>
  )
}

export default VehicleBuilder
